import React from 'react'
import clsx from 'clsx'
import { AvatarType } from './avatar'

const initials = (str: string) => {
  if (!str.length) {
    return 'A'
  }

  const words = str.trim().split(' ')
  if (words.length === 1) {
    return `${words[0].charAt(0)}${words[0].charAt(1) ?? ''}`.toUpperCase()
  }

  const firstInitial = words[0].charAt(0)
  const secondInitial = words[1]?.charAt(0) ?? ''

  return `${firstInitial}${secondInitial}`.toUpperCase()
}

export const Avatar: React.FC<AvatarType> = ({
  source,
  type = 'circular',
  size = 'medium',
  bgColor = 'bg-highlight-400',
  textColor = 'text-highlight-contrast-500',
  alt,
  altContent,
  className,
}) => {
  let clazz = {}
  const isLandscape = type === 'landscape'
  switch (size) {
    case 'xss':
      clazz = {
        'h-4 w-4': !isLandscape,
      }
      break
    case 'xs':
      clazz = {
        'w-12': isLandscape,
        'h-8 w-8': !isLandscape,
      }
      break
    case 'rank':
      clazz = {
        'h-8 w-40': true,
      }
      break
    case 'small':
      clazz = { 'w-14': isLandscape, 'h-10 w-10': !isLandscape }
      break
    case 'medium':
      clazz = { 'w-16': isLandscape, 'h-12 w-12': !isLandscape }
      break
    case 'large':
      clazz = { 'w-20': isLandscape, 'h-14 w-14': !isLandscape }
      break
    case 'xl':
      clazz = { 'w-24': isLandscape, 'h-20 w-20': !isLandscape }
      break
    case 'xxl':
      clazz = { 'w-40': isLandscape, 'h-36 w-36': !isLandscape }
      break
    case 'huge':
      clazz = {
        'w-80': isLandscape,
        'flex h-80 w-80 mt-6 ml-auto mb-4 mr-auto': !isLandscape,
      }
      break
  }

  const resolvedAltContent = altContent ?? alt

  const altDisplay = resolvedAltContent ? (
    <div
      className={clsx(
        'inline-flex p-3 items-center justify-center border-1 border-gray-400 dark:border-slate-400',
        {
          'rounded-full': type === 'circular',
          'rounded-md': type === 'square' || type === 'landscape',
          ...clazz,
        },
        className,
        bgColor,
      )}
      title={resolvedAltContent}
    >
      <p
        className={clsx(
          'font-semibold dark:text-slate-900',
          {
            // 'text-md': size === 'xs',
            'text-xs': size === 'small' || size === 'xs' || size === 'xss',
            'text-xl': size === 'medium',
            'text-2xl': size === 'large',
            'text-3xl': size === 'xl' || size === 'huge' || size === 'xxl',
          },
          textColor,
        )}
      >
        {initials(resolvedAltContent)}
      </p>
    </div>
  ) : null
  return source ? (
    <img
      className={clsx('inline-block', className, {
        'rounded-full': type === 'circular',
        'rounded-md': type === 'square' || type === 'landscape',
        ...clazz,
      })}
      src={source}
      alt={alt || altContent}
      title={alt || altContent}
    />
  ) : (
    altDisplay
  )
}
