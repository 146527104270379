import React from 'react'
import clsx from 'clsx'
import { Link as RemixLink, LinkProps } from '@remix-run/react'

export type { LinkProps }

export const LinkButton: React.FC<
  LinkProps & {
    variant?: 'primary' | 'secondary' | 'error' | 'warn' | 'gray' | 'unstyled'
  }
> = ({ className, children, variant = 'primary', ...props }) => {
  let clazz = ''
  switch (variant) {
    case 'primary':
      clazz =
        'hover:text-white hover:bg-primary-700 focus:bg-primary-700 focus:ring-primary-300 text-white bg-primary-600 disabled:bg-primary-300'
      break
    case 'secondary':
      clazz =
        'hover:bg-gray-50 focus:bg-gray-100 focus:ring-gray-300 text-gray-700 bg-white disabled:text-gray-400 disabled:bg-gray-50'
      break
    case 'warn':
      clazz =
        'hover:text-white hover:bg-warn-700 focus:bg-warn-700 focus:ring-warn-300 text-white bg-warn-600 disabled:bg-warn-300'
      break
    case 'error':
      clazz =
        'hover:text-white hover:bg-error-700 focus:bg-error-700 focus:ring-error-300 text-white bg-error-600 disabled:bg-error-300'
      break
    case 'gray':
      clazz =
        'bg-gray-400 text-white hover:bg-gray-300 focus:bg-gray-500 focus:ring-gray-300 disabled:bg-gray-300 disabled:text-gray-400 dark:bg-slate-400 dark:hover:bg-slate-300 dark:disabled:bg-slate-600 dark:disabled:text-slate-300'
      break
  }

  return (
    <RemixLink
      className={clsx(
        'inline-flex items-center justify-center px-12 py-2 border border-transparent rounded-full font-medium cursor-pointer',
        // 'text-white hover:bg-primary-600 focus:outline-none focus:ring focus:border-primary-700 active:bg-primary-800 bg-primary-600 ',
        clsx(
          'focus:outline-none focus:z-10 focus:ring-1 focus:ring-offset-1 border font-normal flex items-center justify-around gap-2 disabled:cursor-not-allowed',
          clazz,
          className,
        ),
      )}
      role="link"
      {...props}
    >
      {children}
    </RemixLink>
  )
}
