import clsx from 'clsx'
import React, { PropsWithChildren } from 'react'

type FormElementProps = PropsWithChildren<{
  name: string
  label?: string | React.ReactNode
  description?: string
  required?: boolean
  labelBgColor?: string
  error?: string
  helperText?: string
  labelStyle?: 'transition' | 'fixed'
  className?: string
  containerClassName?: string
}>

export const FormElement = React.forwardRef<HTMLDivElement, FormElementProps>(
  (
    {
      children,
      label,
      description,
      name,
      required = false,
      labelBgColor,
      error,
      helperText,
      labelStyle = 'fixed',
      className,
      containerClassName,
    },
    ref,
  ) => {
    return (
      <div
        ref={ref}
        className={clsx({ 'w-full mt-1 relative': !!label }, className)}
      >
        {label && (
          <label
            htmlFor={name}
            className={clsx('whitespace-normal', {              
              'block text-sm font-medium leading-6 text-gray-900 dark:text-slate-400':
                labelStyle === 'fixed',
              'absolute text-sm font-medium  text-gray-500 dark:text-slate-400 duration-300 transform -translate-y-4 scale-75 top-2 origin-[0] dark:bg-slate-800 px-2 peer-focus:px-2 peer-focus:text-primary-600 peer-focus:dark:text-primary-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1':
                labelStyle !== 'fixed',
              labelBgColor: !!labelBgColor,
            })}
          >
            {label}
            {required && <span className="text-red-500">*</span>}
          </label>
        )}
        {description && (
          <span className="w-full text-xs text-gray-500 dark:text-slate-300 mt-1 whitespace-normal">
            {description}
          </span>
        )}
        <div className={clsx('mt-1', containerClassName)}>{children}</div>
        {helperText && (
          <p className="py-1 text-gray-400 text-xs whitespace-normal">
            {helperText}
          </p>
        )}
        {error && (
          <p className="py-1 text-xs text-red-600 dark:text-red-400">{error}</p>
        )}
      </div>
    )
  },
)

export default FormElement
