import React from 'react'
import type { CounterInputProps } from './counter-input'

export const CounterInput = React.forwardRef<
  HTMLInputElement,
  CounterInputProps
>(
  (
    {
      name,
      value,
      label,
      error,
      required,
      labelBgColor,
      helperText,
      onChange,
      children,
      ...props
    },
    ref,
  ) => {
    const [val, setVal] = React.useState<number>(value)
    const handleDecrease = React.useCallback(
      (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        if (val <= 1 || val !== value) {
          return
        }
        setVal(value - 1)
        onChange(value - 1)
      },
      [val, value, onChange],
    )
    const handleAdd = React.useCallback(
      (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        if (val !== value) {
          return
        }
        setVal(value + 1)
        onChange(value + 1)
      },
      [val, value, onChange],
    )
    React.useEffect(() => {
      if (value) {
        setVal(value)
      }
    }, [value])
    return (
      <div className="relative inline-flex grow-0 items-center">
        <button
          type="button"
          id="decrement-button"
          data-input-counter-decrement="quantity-input"
          className="shrink-0 px-3 py-2 bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-600 hover:bg-gray-200 border border-gray-300 rounded-s-md focus:ring-gray-100 dark:focus:ring-gray-700 focus:ring-2 focus:outline-none"
          onClick={handleDecrease}
        >
          <svg
            className="w-3 h-3 text-gray-900 dark:text-slate-400"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 18 2"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M1 1h16"
            />
          </svg>
        </button>
        <div className="flex grow-1 shrink-1">
          <input
            type="text"
            value={val}
            id="quantity-input"
            data-input-counter
            disabled
            aria-describedby="helper-text-explanation"
            className="flex w-12 py-1 px-0.5 bg-gray-50 border-x-0 border-gray-300 text-center text-gray-900 dark:text-slate-400 text-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
          />
        </div>
        <button
          type="button"
          id="increment-button"
          data-input-counter-increment="quantity-input"
          className="shrink-0 px-3 py-2 bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-600 hover:bg-gray-200 border border-gray-300 rounded-e-md focus:ring-gray-100 dark:focus:ring-gray-700 focus:ring-2 focus:outline-none"
          onClick={handleAdd}
        >
          <svg
            className="w-3 h-3 text-gray-900 dark:text-slate-400"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 18 18"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 1v16M1 9h16"
            />
          </svg>
        </button>
      </div>
    )
  },
)
