import clsx from 'clsx'
import React from 'react'
import FormElement from '~ui/FormElement'
import { FormInputProps } from '~ui/FormInput/form-input'

export const ColorPicker = React.forwardRef<HTMLInputElement, FormInputProps>(
  (
    {
      name,
      label,
      helperText,
      error,
      required,
      labelBgColor,
      containerClassName,
      children,
      ...props
    },
    ref,
  ) => {
    return (
      <FormElement
        name={name}
        label={label}
        required={required}
        labelBgColor={labelBgColor}
        error={error}
        helperText={helperText}
        className={containerClassName}
      >
        <input
          ref={ref}
          name={name}
          {...props}
          className={clsx(
            'inline-block h-12 w-14 text-sm text-gray-900 dark:text-slate-400 bg-transparent rounded-lg border-1',
            'dark:text-gray',
            'dark:focus:border-primary-500 focus:outline-none focus:ring-0 focus:border-primary-600 peer',
            {
              'border-error-500 focus:border-error-500': error,
              'border-gray-300 dark:border-gray-600': !error,
            },
            props.className,
          )}
          type="color"
        />
        {children}
      </FormElement>
    )
  },
)
