import React, { useEffect } from 'react'
import clsx from 'clsx'
import Datepicker, {
  DateType,
  DateValueType,
} from 'react-tailwindcss-datepicker'
import type { DateRangeSelectorProps } from './date-range-selector'

export const DateRangeSelector: React.FC<DateRangeSelectorProps> = ({
  configs,
  startDate,
  endDate = new Date(),
  containerClassName,
  inputClassName,
  onChangeDateRange,
}) => {

  const [value, setValue] = React.useState<DateValueType>({
    startDate: startDate || null,
    endDate: endDate || null,
  })

  const handleChange = React.useCallback(
    (val: DateValueType) => {
      setValue(val)
      if(onChangeDateRange && val?.startDate && val?.endDate ){
        onChangeDateRange(val.startDate, val.endDate)
      }
    },
    [onChangeDateRange],
  )

  useEffect(() => {
    setValue({ startDate, endDate })
  }, [startDate, endDate])

  return (
    <Datepicker
      value={value}
      useRange
      configs={configs}
      showShortcuts
      onChange={handleChange}
      displayFormat={'MMM DD, YYYY'}
      inputClassName={clsx(
        'relative pl-2.5 pr-14 py-2.5 w-full rounded-md border-1 text-sm bg-white text-gray-900 dark:bg-slate-900 dark:text-slate-400 dark:border-slate-500',
        'peer appearance-none',
        'focus:outline-none focus:ring-0 focus:border-primary-600 dark:focus:border-primary-500',
        inputClassName,
      )}
      containerClassName={clsx('relative', containerClassName)}
    />
  )
}
