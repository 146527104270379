import { useState } from 'react'
import { RadioGroup } from '@headlessui/react'
import clsx from 'clsx'
import { SelectOptionType } from '~ui/types'

const defaultOptions = [
  {
    label: 'And',
    value: 'true',
  },
  { label: 'Or', value: 'false' },
]

interface TwoOptionRadioProps {
  label?: string
  options?: SelectOptionType[]
  selected: string
  onSelect: (value: string) => void
}

export default function TwoOptionRadio({
  label,
  options = defaultOptions,
  selected,
  onSelect,
}: TwoOptionRadioProps) {
  return (
    <RadioGroup value={selected} onChange={onSelect}>
      {label && (
        <RadioGroup.Label className="block text-sm font-medium leading-6 text-gray-900 dark:text-slate-400">
          {label}
        </RadioGroup.Label>
      )}

      <div className="flex items-center">
        {options.map((option, index) => (
          <RadioGroup.Option
            key={option.label}
            value={option.value}
            className={({ active, checked }) =>
              clsx(
                index === 0 ? 'rounded-l-md' : 'rounded-r-md',
                checked ? 'bg-primary-600 text-white' : 'text-gray-700',
                'relative flex cursor-pointer items-center justify-center p-2 focus:outline-none border border-gray-200 dark:border-slate-500 w-12 h-8 text-sm',
              )
            }
          >
            <RadioGroup.Label as="span" className="sr-only">
              {option.label}
            </RadioGroup.Label>
            <span className="">{option.label}</span>
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  )
}
