import React from 'react'
import clsx from 'clsx'
import { UserCardType } from './user-card'
import { Avatar } from '..'

export const UserMiniCard: React.FC<UserCardType> = ({
  id,
  firstName,
  lastName,
  title,

  avatar,
  onClick,
}) => {
  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.preventDefault()
      if (onClick && id) {
        onClick(id)
      }
    },
    [],
  )
  return (
    <div
      className={clsx(
        'p-4 flex space-x-3 rounded-lg border bg-white border-gray-100 dark:bg-slate-800 dark:border-slate-500',
        {
          'cursor-pointer hover:bg-secondary-100 dark:hover:bg-slate-700': onClick && id,
        },
      )}
      onClick={handleClick}
    >
      <div className="flex-shrink-0 flex align-center justify-center">
        <Avatar
          size="xs"
          source={avatar?.source}
          alt={`${firstName} ${lastName}`}
          altContent={`${firstName.charAt(0)}${lastName.charAt(0)}`}
        />
      </div>
      <div className="relative min-w-0 flex-1">
        <a href="#" className="focus:outline-none">
          <span className="absolute inset-0" aria-hidden="true" />
          <p className="text-sm font-bold text-gray-900 dark:text-slate-400">
            {firstName} {lastName}
          </p>
          <p className="truncate text-sm text-gray-500">{title}</p>
        </a>
      </div>
    </div>
  )
}
