import clsx from 'clsx'
import { LockClosedIcon } from '@heroicons/react/24/outline'
import React, { useEffect, useState } from 'react'

import FormElement from '~ui/FormElement'
import { SelectOptionType } from '~ui/types'

interface MultiSelectInputProps {
  label?: string
  error?: string
  disabled?: boolean
  initialOptions: SelectOptionType[]
  containerClassName?: string
  onChange?: (values: string[]) => void
  onAdd?: (value: string) => void
  onRemove?: (value: string) => void
}

export function MultiSelectInput({
  label,
  error,
  disabled,
  initialOptions,
  containerClassName,
  onChange,
  onAdd,
  onRemove,
}: MultiSelectInputProps) {
  const [options, setOptions] = useState<SelectOptionType[]>(initialOptions)
  const [inputValue, setInputValue] = useState<string>('')

  useEffect(() => {
    setOptions(initialOptions)
  }, [initialOptions])

  const handleRemove = (value: string) => {
    setOptions((old: SelectOptionType[]) =>
      old.filter((option: SelectOptionType) => option.value !== value),
    )
    if (onRemove) {
      onRemove(value)
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleOptionUpdate()
    }
  }

  const handleOptionUpdate = () => {
    if (!inputValue.length) return
    const newOptions = [
      ...options,
      {
        label: inputValue,
        value: inputValue,
        readonly: false,
      },
    ]
    setOptions(newOptions)
    if (onChange) {
      onChange(newOptions.map((option) => option.value))
    }
    if (onAdd) {
      onAdd(inputValue)
    }
    setInputValue('')
  }

  return (
    <FormElement
      name="multi-select"
      label={label}
      className={containerClassName}
    >
      <div className="cursor-default dark:bg-slate-800 relative w-full h-fit rounded-md border border-gray-300 bg-white pl-3 pr-10 py-2 text-left focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition ease-in-out duration-150 sm:text-sm sm:leading-5">
        <div className="w-full flex flex-wrap justify-start items-center gap-2">
          {options.map((option: SelectOptionType, index: number) => (
            <div
              key={`${option.label}-${index}`}
              className={clsx(
                'inline-flex items-center px-2 py-1 mr-1 rounded text-xs text-white',
                option.readonly ? 'bg-purple-800' : ' bg-green-600',
              )}
            >
              {option.label}
              <div
                className={clsx(
                  'ml-1 rounded-full',
                  option.readonly
                    ? 'bg-purple-100 cursor-not-allowed'
                    : 'bg-gray-100 cursor-pointer',
                )}
                onClick={() => {
                  if (!option.readonly) {
                    handleRemove(option.value)
                  }
                }}
              >
                {option.readonly ? (
                  <LockClosedIcon className="w-4 h-3 text-purple-900" />
                ) : (
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M4.29289 4.29289C4.68342 3.90237 5.31658 3.90237 5.70711 4.29289L10 8.58579L14.2929 4.29289C14.6834 3.90237 15.3166 3.90237 15.7071 4.29289C16.0976 4.68342 16.0976 5.31658 15.7071 5.70711L11.4142 10L15.7071 14.2929C16.0976 14.6834 16.0976 15.3166 15.7071 15.7071C15.3166 16.0976 14.6834 16.0976 14.2929 15.7071L10 11.4142L5.70711 15.7071C5.31658 16.0976 4.68342 16.0976 4.29289 15.7071C3.90237 15.3166 3.90237 14.6834 4.29289 14.2929L8.58579 10L4.29289 5.70711C3.90237 5.31658 3.90237 4.68342 4.29289 4.29289Z"
                      fill="#15803d"
                    />
                  </svg>
                )}
              </div>
            </div>
          ))}
        </div>
        <input
          value={inputValue}
          disabled={disabled}
          onChange={(e) => {
            setInputValue(e.target.value)
          }}
          onBlur={handleOptionUpdate}
          onKeyDown={handleKeyDown}
          className={clsx(
            'dark:bg-slate-800 block p-0 w-full text-sm text-gray-900 dark:text-slate-400 bg-white border-0',
            'appearance-none dark:text-gray',
            'dark:focus:border-primary-500 focus:outline-none focus:ring-0 peer',
            options.length > 0 && 'mt-2',
          )}
        />
      </div>
    </FormElement>
  )
}
