import React from 'react'
import clsx from 'clsx'
import { TableProps } from './table'
export type { TableProps }

export const Table: React.FC<React.PropsWithChildren<TableProps>> = ({
  className,
  children,
}) => {
  return (
    <table
      className={clsx(
        'min-w-full divide-y divide-gray-300 dark:divide-slate-500',
        className,
      )}
    >
      {children}
    </table>
  )
}
