import { ReactNode } from 'react'
import { Tab } from './Tab'
import { TabProps } from './Tab/tab'

interface TabContainerProps {
  tabs: TabProps[]
  children: ReactNode | string
}

export function TabsContainer({ tabs, children }: TabContainerProps) {
  return (
    <>
      <div className="dark:bg-slate-900 dark:border-slate-500 border-b border-gray-200 dark:border-slate-500 bg-white mb-4">
        <div className="max-w-6xl xl:mx-auto px-4">
          <ul className="gap-x-4 grid grid-cols-2 sm:grid-0 sm:flex sm:flex-wrap -mb-px text-sm font-medium text-center text-gray-500">
            {tabs.map((props) => (
              <Tab
                Icon={props.Icon}
                name={props.name}
                to={props.to}
                key={props.name}
              />
            ))}
          </ul>
        </div>
      </div>
      {children}
    </>
  )
}
