import clsx from 'clsx'
import { VerticalStackProps } from './vertical-stack'
export type { VerticalStackProps }

export const VerticalStack: React.FC<VerticalStackProps> = ({
  className,
  children,
}) => {
  return (
    <div className={clsx('grid grid-cols-1 divide-y-2', className)}>
      {children}
    </div>
  )
}
