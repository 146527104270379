import React from 'react'
import { RadioButtonProps } from './radio'
import clsx from 'clsx'

export type { RadioButtonProps }

const RadioButton: React.FC<RadioButtonProps> = ({
  id,
  label,
  name,
  value,
  checked,
  disabled = false,
  helperText = null,
  isError = false,
  onChange,
}) => {
  return (
    <div className="flex">
      <div className="h-5">
        <input
          id={id || name}
          type="radio"
          checked={checked}
          name={name}
          value={value}
          disabled={disabled}
          onChange={onChange}
          className={clsx(
            'w-4 h-4 focus:ring-2',
            'bg-white checked:bg-primary-600 border-gray-300 focus:ring-gray-200',
            'dark:bg-slate-400 dark:border-slate-500 dark:focus:ring-slate-300 dark:ring-offset-slate-800',
          )}
        />
      </div>
      <div className="ml-1">
        <label
          htmlFor={id || name}
          className={`ml-2 text-base font-medium text-gray-${
            disabled ? '400' : '900'
          } dark:text-gray-${!disabled ? '500' : '300'}`}
        >
          {label}
        </label>
        {helperText ? (
          <p
            className={`text-sm font-normal ${
              isError ? 'text-error-500' : 'text-gray-500'
            } pl-2 mb-2`}
          >
            {helperText}
          </p>
        ) : (
          <p className="mb-2"></p>
        )}
      </div>
    </div>
  )
}

export default RadioButton
