import React from 'react'
import clsx from 'clsx'
import { PhotoIcon } from '@heroicons/react/24/outline'
import FormElement from '~ui/FormElement'
import { FileInputAreaProps } from './file-input-area'

export const FileInputArea: React.FC<FileInputAreaProps> = ({
  name,
  label,
  required,
  error,
  uploading,
  helperText,
  containerClassName,
  onChange,
}) => {
  const [dragActive, setDragActive] = React.useState<boolean>(false)
  const inputRef = React.useRef<any>(null)

  const handleChange = React.useCallback(
    (e: any) => {
      e.preventDefault()
      console.log('File has been added')
      if (!uploading && e.target.files && e.target.files[0]) {
        onChange(e.target.files[0])
      }
    },
    [onChange, uploading],
  )

  const handleDrop = React.useCallback(
    (e: any) => {
      e.preventDefault()
      e.stopPropagation()
      setDragActive(false)
      if (!uploading && e.dataTransfer.files && e.dataTransfer.files[0]) {
        onChange(e.dataTransfer.files[0])
      }
    },
    [onChange, uploading],
  )

  const handleDragLeave = React.useCallback((e: any) => {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)
  }, [])

  const handleDragOver = React.useCallback((e: any) => {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(true)
  }, [])

  const handleDragEnter = React.useCallback((e: any) => {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(true)
  }, [])

  const openFileExplorer = React.useCallback(() => {
    inputRef.current.value = ''
    inputRef.current.click()
  }, [inputRef])

  return (
    <FormElement
      name={name}
      label={label}
      required={required}
      error={error}
      helperText={helperText}
      className={containerClassName}
    >
      <div
        className={clsx(
          'mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10',
          {
            'bg-primary-100': dragActive,
          },
        )}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <div className="text-center">
          <PhotoIcon
            className="mx-auto h-12 w-12 text-gray-300 dark:text-slate-400"
            aria-hidden="true"
          />
          <div
            className={clsx(
              'mt-4 flex text-sm leading-6 text-gray-600 dark:text-slate-300',
              {
                'justify-center': uploading,
              },
            )}
          >
            {uploading ? (
              <span>Uploading...</span>
            ) : (
              <>
                <label
                  htmlFor="file-upload"
                  className="relative cursor-pointer rounded-md font-semibold focus-within:outline-none bg-white text-indigo-600 hover:text-indigo-500 dark:bg-slate-800"
                >
                  <span onClick={openFileExplorer}>Upload a file</span>
                  <input
                    ref={inputRef}
                    id="file-upload"
                    name="file-upload"
                    type="file"
                    className="sr-only"
                    multiple={false}
                    onChange={handleChange}
                    accept="image/*"
                  />
                </label>
                <p className="pl-1">or drag and drop</p>
              </>
            )}
          </div>
          <p className="text-xs leading-5 text-gray-600 dark:text-slate-300">
            PNG, JPG, GIF up to 10MB
          </p>
        </div>
      </div>
    </FormElement>
  )
}
