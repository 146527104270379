import { NavLink, useMatches, useResolvedPath } from '@remix-run/react'
import { TabProps } from './tab'

export function Tab({ Icon, name, to }: TabProps) {
  const resolved = useResolvedPath(to)
  const matches = useMatches()
  const isActive = matches.find((m) => m.pathname === resolved.pathname)

  return (
    <li className={isActive ? `cursor-default` : `cursor-pointer`}>
      <NavLink
        to={to}
        className={`group w-full gap-x-2 max-w-[12rem] inline-flex items-center justify-around p-4 rounded-t-lg border-b-2 font-semibold ${
          isActive
            ? 'text-blue-800 border-blue-800 dark:text-slate-4700'
            : 'border-transparent dark:text-slate-400 hover:text-gray-600 hover:border-gray-300'
        }`}
      >
        {Icon ? <Icon
          className={`w-5 h-5 ${
            isActive
              ? 'text-blue-800'
              : 'text-gray-600 group-hover:text-gray-500'
          }`}
        /> : null}
        <p className="flex-1">{name}</p>
      </NavLink>
    </li>
  )
}
