import { ReactNode, useState } from 'react'

interface TooltipProps {
    text?: string;
    children: ReactNode;
  }

export function Tooltip({ text, children }: TooltipProps) {
    const [visible, setVisible] = useState(false);

  return (
    <span
      className="relative flex items-center"
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
    >
      {visible && (
        <div className="absolute bottom-full mb-2 w-max bg-gray-800 text-white text-sm rounded py-1 px-2 z-10">
          {text}
        </div>
      )}
      {children}
    </span>
  );
}
