import React from 'react'
import { TableBodyProps } from './table-body'
export type { TableBodyProps }

export const TableBody: React.FC<React.PropsWithChildren<TableBodyProps>> = ({
  children,
  showBorders = true,
}) => {
  return (
    <tbody className="divide-y divide-gray-200 dark:divide-slate-600 bg-white dark:bg-slate-800">
      {children}
    </tbody>
  )
}
