import React from 'react'
import clsx from 'clsx'
import { TableRowProps } from './table-row'
export type { TableRowProps }

export const TableRow: React.FC<TableRowProps> = ({
  highlighted,
  children,
  className,
}) => {
  return (
    <tr
      className={clsx(
        { 'bg-gray-50 dark:bg-slate-400': highlighted },
        className,
      )}
    >
      {children}
    </tr>
  )
}
