import clsx from 'clsx'
import { CardStackProps } from './card-stack'
export type { CardStackProps }

export const CardStack: React.FC<CardStackProps> = ({
  className,
  children,
}) => {
  return (
    <div className={clsx('grid grid-cols-1 divide-y-2', className)}>
      {children}
    </div>
  )
}
