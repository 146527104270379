import clsx from 'clsx'

export const Paper: React.FC<
  React.PropsWithChildren<{ className?: string }>
> = ({ className, children }) => {
  return (
    <div
      className={clsx(
        'ring-1 rounded-md px-4 pt-2.5 pb-3',
        'bg-gray-50 ring-gray-200 dark:bg-slate-800 ring-slate-500',
        className,
      )}
    >
      {children}
    </div>
  )
}
