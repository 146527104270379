import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import FormElement from '~ui/FormElement'
import CurrencyInput, { CurrencyInputProps } from 'react-currency-input-field'

type MoneyInputProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'placeholder'
> & {
  label?: string
  description?: string
  helperText?: string
  name: string
  value: number | string
  error?: string
  prefix?: string
  containerClassName?: string
}

export const MoneyInput = React.forwardRef<
  HTMLInputElement,
  MoneyInputProps
>(({ name, value, error, prefix = '$', onChange, ...props }, ref) => {
  const [parsedValue, setParsedValue] = useState<string>('')

  useEffect(() => {
    setParsedValue(value ? String(Number(value) / 100) : '')
  }, [value])

  const handleOnValueChange: CurrencyInputProps['onValueChange'] = (
    _value,
    name,
    _values,
  ) => {
    const val = Math.round(_values ? (_values?.float! * 100) : 0)
    const newValue = val.toFixed(0)
    if(onChange){
      onChange({
        // @ts-ignore
        target: { value: `${val}` },
        // @ts-ignore
        currentTarget: { value: `${val}` },
        bubbles: false,
        cancelable: false,
        defaultPrevented: false,
        preventDefault: () => {},
        stopPropagation: () => {},
        type: 'change',
      })
    }
    if (!_value) {
      setParsedValue('')
      return
    }

    setParsedValue(_value)

  }

  return (
    <FormElement name={name} error={error} {...props}>
      <div className={clsx('flex justify-start items-center')}>
        {prefix && (
          <p className="py-2.5 h-full w-5 text-sm font-medium rounded-l-md text-center border-l border-y bg-gray-200 text-gray-500 border-gray-300 dark:bg-slate-800 dark:text-slate-300 dark:border-slate-500">
            {prefix}
          </p>
        )}
        <CurrencyInput
          id="currency_input"
          name="currency_input"
          className={clsx(
            'block px-2.5 py-2.5 w-full text-sm text-gray-900 dark:bg-slate-900 dark:text-slate-400 bg-transparent',
            'appearance-none',
            'border focus:outline-none focus:ring-0 peer focus:border-primary-600 dark:focus:border-slate-500',
            {
              'border-error-500 focus:border-error-500 dark:border-error-600':
                !!error,
              'border-gray-300 dark:border-gray-600 ': !error,
            },
            {
              'rounded-r-md': prefix,
            },
            props.className,
          )}
          value={parsedValue}
          onValueChange={handleOnValueChange}
          placeholder="Please enter a number"
          decimalScale={2}
          step={100}
          ref={ref}
        />
        {/* <input type="hidden" name={name} value={value} /> */}
      </div>
    </FormElement>
  )
})
