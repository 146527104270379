import {
  Dialog,
  DialogTitle,
  DialogPanel,
  Transition,
  TransitionChild,
} from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/solid'
import { ModalProvider, useModal } from './modal-context'
import clsx from 'clsx'
import React, { Fragment, PropsWithChildren } from 'react'
import { IconButton } from '~ui/IconButton'

type ModalProps = {
  isOpen: boolean
  onClose: () => void
  size?: 'small' | 'medium' | 'large'
  afterClose?: () => void
  afterOpen?: () => void
}

const Modal: React.FC<PropsWithChildren<ModalProps>> & {
  Title: React.FC<PropsWithChildren>
  Description: React.FC<PropsWithChildren>
  Body: React.FC<PropsWithChildren>
  Footer: React.FC<
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    >
  >
} = ({ isOpen, onClose, size = 'medium', children, afterClose, afterOpen }) => {
  const handleClose = React.useCallback(
    (value: boolean) => {
      onClose()
    },
    [onClose],
  )
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-[75]" onClose={onClose}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterEnter={afterOpen}
          afterLeave={afterClose}
        >
          <div className="fixed inset-0 bg-gray-700 bg-opacity-75 backdrop-blur-sm" />
        </TransitionChild>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center sm:p-4 text-center">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <DialogPanel
                className={clsx(
                  'flex flex-col justify-start w-full max-w-full h-dvh sm:max-w-2xl sm:h-full sm:min-h-96 sm:max-h-full overflow-auto transform p-10 text-left align-middle shadow-xl transition-all border rounded-lg',
                  'bg-white dark:bg-slate-800 border-transparent dark:border-slate-700 dark:shadow-slate-800',
                  {
                    'max-w-md': size === 'small',
                    'max-w-xl': size === 'medium',
                    'max-w-3xl': size === 'large',
                  },
                )}
              >
                <ModalProvider close={onClose}>{children}</ModalProvider>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

const Title: React.FC<PropsWithChildren> = ({ children }) => {
  const { close } = useModal()

  return (
    <DialogTitle className="flex items-center justify-between">
      <div className="text-2xl dark:text-slate-400">{children}</div>
      <div className="absolute top-4 right-4">
        <IconButton
          icon={<XMarkIcon className="w-6 h-6"></XMarkIcon>}
          onClick={close}
        />
      </div>
    </DialogTitle>
  )
}

const Description: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Dialog.Description className="flex text-small-regular text-gray-700 items-center justify-center pt-2 pb-4 h-full">
      {children}
    </Dialog.Description>
  )
}

const Body: React.FC<PropsWithChildren> = ({ children }) => {
  return <div className="flex-1">{children}</div>
}

const Footer: React.FC<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
> = ({ className, children }) => {
  return (
    <div className={`flex items-center justify-end gap-x-4 ${className}`}>
      {children}
    </div>
  )
}

Modal.Title = Title
Modal.Description = Description
Modal.Body = Body
Modal.Footer = Footer

export default Modal
