import React from 'react'
import clsx from 'clsx'
import { XMarkIcon } from '@heroicons/react/20/solid'
import { StatusProps } from './status'

export type { StatusProps }

export const Status = React.forwardRef<HTMLDivElement, StatusProps>(
  ({ title, message, icon, onHide }, forwardefRef) => {
    return (
      <div
        ref={forwardefRef}
        className="p-3 min-h-16 pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg shadow-lg ring-1 ring-opacity-5 bg-white ring-black dark:bg-slate-800 dark:ring-slate-500"
      >
        <div
          className={clsx('flex', {
            'items-center': !message,
            'items-start': !!message,
          })}
        >
          <div className="flex-shrink-0">{icon}</div>
          <div className="ml-3 w-0 flex-1 pt-0.25">
            {title ? (
              <p className="text-sm font-medium -mt-0.25 mb-0.5 text-gray-900 dark:text-slate-300">
                {title}
              </p>
            ) : null}
            <p className="text-sm text-gray-500 dark:text-slate-400">
              {message}
            </p>
          </div>
          <div className="ml-4 flex flex-shrink-0">
            <button
              type="button"
              className="inline-flex rounded-md focus:outline-none focus:ring-2 bg-white text-gray-400 hover:text-gray-500 focus:ring-indigo-500 focus:ring-offset-2 dark:bg-slate-800 dark:text-slate-400"
              onClick={onHide}
            >
              <span className="sr-only">Close</span>
              <XMarkIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    )
  },
)
