import { StarIcon } from '@heroicons/react/24/solid'

interface RatingProps {
  width: string
  rating: number
}

export function Rating({ width, rating }: RatingProps) {
  const renderStars = () => {
    const stars = []
    const totalStars = 5

    const isIntegerOrFloatWithDecimals = (value: number) =>
      Number.isInteger(value) || value % 1 !== 0.0

    for (let i = 1; i <= totalStars; i++) {
      const starValue = i - rating
      const isFullStar = starValue <= -0.25
      const isHalfStar = starValue > -0.25 && starValue < 0.75

      stars.push(
        <div key={i} className="flex items-center">
          {isFullStar && <StarIcon className="text-yellow-500" width={width} />}
          {isHalfStar && isIntegerOrFloatWithDecimals(rating) && (
            <div className="half-star-container">
              <StarIcon
                className="text-yellow-500"
                width={width}
                style={{ position: 'absolute' }}
              />
              <StarIcon
                className="text-gray-200"
                width={width}
                style={{
                  clipPath: `polygon(${
                    (rating % 1) * 100
                  }% 0, 100% 0, 100% 100%, ${(rating % 1) * 100}% 100%)`,
                }}
              />
            </div>
          )}
          {!isFullStar && !isHalfStar && (
            <StarIcon className="text-gray-200" width={width} />
          )}
        </div>,
      )
    }

    return stars
  }

  return <div className="flex">{renderStars()}</div>
}

export default Rating
