import { AccordionProps } from './accordion'
import { AccordionItem } from '~ui/AccordionItem'
import { AccordionButton } from '~ui/AccordionButton'
import { AccordionPanel } from '~ui/AccordionPanel'

export type { AccordionProps }

export const Accordion: React.FC<AccordionProps> = ({
  items,
  openIdx,
  children,
  onClick,
}) => {
  return (
    <dl className="mt-10 space-y-6 divide-y divide-white">
      {children
        ? children
        : items?.map((item, idx) => (
            <AccordionItem key={`accordion-${idx}`}>
              <dt>
                <AccordionButton
                  open={openIdx === idx}
                  onClick={() => onClick && onClick(idx)}
                >
                  {item.label}
                </AccordionButton>
              </dt>
              <AccordionPanel>{item.panel}</AccordionPanel>
            </AccordionItem>
          ))}
    </dl>
  )
}
