import React from 'react'
import clsx from 'clsx'
import { Link as RemixLink, LinkProps } from '@remix-run/react'

export type { LinkProps }

export const IconLink: React.FC<LinkProps> = ({ className, children, ...props }) => (
  <RemixLink
    className={clsx(
      'text-base border-0 text-base font-medium',
      'flex items-center justify-around gap-2',
      'disabled:cursor-not-allowed',
      'rounded-md py-2 px-2',
      {
        'text-gray-600 hover:text-gray-500 dark:text-slate-400 hover:dark:text-slate-300 cursor-pointer':
          !className,
      },
      className,
    )}
    role="link"
    {...props}
  >
    {children}
  </RemixLink>
)
