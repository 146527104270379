import React from 'react'
import clsx from 'clsx'
import { Link as RemixLink } from '@remix-run/react'
import { NavBarProps } from './nav-bar'

export function NavBar({ tabs, className, onChange }: NavBarProps) {
  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      onChange(e.currentTarget.value)
    },
    [],
  )

  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 focus:border-primary-500 focus:ring-primary-500"
          defaultValue={tabs.find((tab) => !!tab.current)?.to || ''}
          onChange={handleChange}
        >
          {tabs.map((tab) => (
            <option key={tab.to}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav
          className="flex flex-wrap justify-start items-center gap-4"
          aria-label="Tabs"
        >
          {tabs.map((tab) => (
            <RemixLink
              key={tab.name}
              to={tab.to}
              className={clsx('rounded-md px-3 py-2 text-sm font-medium', {
                'bg-blue-100 border border-blue-900 text-blue-900 dark:bg-slate-900 text-slate-300':
                  tab.current,
                'bg-gray-50 text-gray-500 hover:text-gray-700 dark:bg-slate-800 text-slate-400 dark:hover:text-slate-300':
                  !tab.current,
              })}
              aria-current={tab.current ? 'page' : undefined}
            >
              {tab.name}
            </RemixLink>
          ))}
        </nav>
      </div>
    </div>
  )
}
