import clsx from 'clsx'
import React from 'react'

type InputProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'placeholder'
> & {
  label?: string
  name: string
  labelBgColor?: string
  placeholder?: string
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    { name, label, required, onChange, labelBgColor, placeholder, ...props },
    ref,
  ) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(event)
      }
    }

    return (
      <input
        ref={ref}
        {...props}
        onChange={handleChange}
        placeholder={placeholder}
        className={clsx(
          'block rounded-md border-0 dark shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6',
          'text-gray-900 dark:bg-slate-900 dark:text-slate-400 ring-gray-300 dark:ring-slate-500 placeholder:text-gray-400 focus:ring-indigo-600',
          props.className,
        )}
      >
        {props.children}
      </input>
    )
  },
)
