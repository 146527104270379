import { Disclosure } from '@headlessui/react'
import { AccordionItemProps } from './accordion-item'

export type { AccordionItemProps }

export const AccordionItem: React.FC<AccordionItemProps> = ({
  children
}) => {
  return (
    <Disclosure as="div">
      {children}
    </Disclosure>
  )
}
