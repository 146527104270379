import React from 'react'
import { TimepickerProps } from './timepicker'
import FormElement from '~ui/FormElement'

export type { TimepickerProps }

export const Timepicker: React.FC<TimepickerProps> = ({
  value = '00:00:00',
  name,
  label,
  error,
  helperText,
  required,
  containerClassName,
  onChange,
  onBlur,
  minuteIncrement = 5,
  children,
  ...props
}) => {
  const v = value.split(':')
  if (v.length !== 3) {
    console.log('Error! Input time needs to be format 23:00:00', v)
  }
  const hr = parseInt(v[0], 10)
  const isAm = hr >= 0 && hr < 12
  const [hour, setHour] = React.useState<number>(
    hr === 0 || hr === 12 ? 12 : isAm ? hr : hr - 12,
  )
  const [minute, setMinute] = React.useState<number>(parseInt(v[1], 10))
  const [ampm, setAmpm] = React.useState<string>(isAm ? 'AM' : 'PM')
  const [vals, setVals] = React.useState<string>(value)
  const handleChangeHour = React.useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const v = parseInt(e.target.value, 10)
      setHour(v)
    },
    [],
  )
  const handleChangeMinute = React.useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const v = parseInt(e.target.value, 10)
      setMinute(v)
    },
    [],
  )
  const handleChangeAMPM = React.useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      setAmpm(e.target.value)
    },
    [],
  )
  React.useEffect(() => {
    let hr = ampm === 'PM' && hour !== 12 ? hour + 12 : hour
    if ((ampm === 'PM' && hr === 24) || (ampm === 'AM' && hour === 12)) {
      hr = 0
    }
    const val = `${`${hr}`.padStart(2, '0')}:${`${minute}`.padStart(2, '0')}:00`
    if (vals === val) {
      return
    }
    setVals(val)
    onChange(val)
    if (onBlur) {
      // @ts-ignore ok
      onBlur()
    }
  }, [vals, hour, minute, ampm, onChange, onBlur])

  return (
    <>
      <input type="hidden" name={name} value={vals} />
      <FormElement
        name={name}
        label={label}
        required={required}
        error={error}
        helperText={helperText}
        className={containerClassName}
      >
        <div className="inline-flex items-center shrink-0 text-lg border rounded-md px-2 dark:bg-slate-900 dark:border-slate-500">
          <select
            id="hr"
            value={hour}
            className="px-2 appearance-none bg-none border-transparent outline-transparent shadow-none shadow-transparent focus:outline-transparent bg-transparent focus:ring-transparent focus:ring-offset-transparent"
            onChange={handleChangeHour}
          >
            {new Array(12).fill('').map((_, idx) => (
              <option key={`hr-${idx}`} value={idx + 1}>
                {`${idx + 1}`.padStart(2, '0')}
              </option>
            ))}
          </select>
          <span className="px-2">:</span>
          <select
            id="min"
            value={minute}
            className="px-2 appearance-none bg-none border-transparent outline-transparent shadow-none shadow-transparent focus:outline-transparent bg-transparent focus:ring-transparent focus:ring-offset-transparent"
            onChange={handleChangeMinute}
          >
            {new Array(Math.round(60 / minuteIncrement))
              .fill('')
              .map((_, idx) => (
                <option key={`min-${idx}`} value={idx * minuteIncrement}>
                  {`${idx * minuteIncrement}`.padStart(2, '0')}
                </option>
              ))}
          </select>
          <select
            id="ampm"
            value={ampm}
            className="px-2 appearance-none bg-none border-transparent outline-transparent shadow-none shadow-transparent focus:outline-transparent bg-transparent focus:ring-transparent focus:ring-offset-transparent"
            onChange={handleChangeAMPM}
          >
            <option value="AM">AM</option>
            <option value="PM">PM</option>
          </select>
        </div>
      </FormElement>
    </>
  )
}
