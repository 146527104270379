import clsx from 'clsx'
import { CheckCircleIcon } from '@heroicons/react/24/outline'
import { SuccessBoxProps } from './success-box'

export type { SuccessBoxProps }

export const SuccessBox: React.FC<SuccessBoxProps> = ({
  title,
  message,
  className,
}) => {
  return (
    <div
      className={clsx(
        'p-4 border border-success-600 bg-success-50 dark:bg-slate-900 text-success-600 rounded-lg',
        className,
      )}
    >
      <div className="flex justify-center">
        <div className="flex-grow">
          <CheckCircleIcon
            className="h-20 w-20 m-auto mb-2 text-green-600"
            aria-hidden="true"
          />
        </div>
      </div>
      <p className="text-center font-semibold text-gray-800 dark:text-green-600">{title}</p>
      <p className="text-center text-sm dark:text-green-700">{message}</p>
    </div>
  )
}
