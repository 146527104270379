import React from 'react'
import clsx from 'clsx'
import FormElement from '~ui/FormElement'
import type { SelectProps } from './select'

export type { SelectProps }

export const NativeSelect = React.forwardRef<HTMLSelectElement, SelectProps>(
  (
    {
      name,
      label,
      description,
      error,
      helperText,
      required,
      placeholder = 'Select...',
      noPlaceholder = false,
      containerClassName,
      children,
      ...props
    },
    ref,
  ) => {
    return (
      <FormElement
        name={name}
        error={error}
        label={label}
        description={description}
        helperText={helperText}
        required={required}
        className={containerClassName}
      >
        <select
          ref={ref}
          name={name}
          {...props}
          className={clsx(
            'dark:bg-slate-900 dark:text-slate-400 block w-full rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:focus:border-slate-300 sm:text-sm',
            {
              'border-error-500 focus:border-error-500': error,
              'border-gray-300 dark:border-gray-600': !error,
            },
            props.className,
          )}
        >
          {!noPlaceholder && <option value="">{placeholder}</option>}
          {children}
        </select>
      </FormElement>
    )
  },
)
