import clsx from 'clsx'
import { AvatarType } from '~ui/Avatar/avatar'
import { Avatar } from '..'
import { AvatarGroupType } from './avatar-group'

export const AvatarGroup: React.FC<AvatarGroupType> = ({
  avatars,
  className,
}) => {
  return (
    <div
      className={clsx(
        'isolate flex p-0.5 -space-x-1 overflow-hidden',
        className,
      )}
    >
      {avatars.map((avatar: AvatarType, idx) => (
        <Avatar
          key={`avatar-${idx}`}
          {...avatar}
          className={`relative z-${
            avatars.length * 10 - idx * 10
          } inline-block rounded-full ring-2 ring-white`}
        />
      ))}{' '}
    </div>
  )
}
