import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogPanel,
  Transition,
  TransitionChild,
} from '@headlessui/react'
import { ExclamationTriangleIcon, XMarkIcon } from '@heroicons/react/24/outline'
import clsx from 'clsx'

import { Button } from '../Button'

import { ConfirmModalType } from './confirm-modal'

export const ConfirmModal: React.FC<
  React.PropsWithChildren<ConfirmModalType>
> = ({
  title,
  confirmLabel = 'Confirm',
  open,
  onConfirm,
  onCancel,
  children,
}) => {
  return (
    <Transition show={open} as={React.Fragment}>
      <Dialog
        id="confirm-form"
        as="div"
        className="relative z-100"
        onClose={onCancel}
      >
        <TransitionChild
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-opacity-75 transition-opacity bg-gray-500" />
        </TransitionChild>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel
                className={clsx(
                  'relative transform overflow-hidden rounded-lg px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6',
                  'bg-white dark:bg-slate-800',
                )}
              >
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md focus:outline-none bg-white text-gray-400 hover:text-gray-500 dark:bg-slate-800 dark:text-slate-400 dark:hover:text-slate-300"
                    onClick={onCancel}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10 bg-red-100 dark:bg-opacity-10">
                    <ExclamationTriangleIcon
                      className="h-6 w-6 text-red-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <DialogTitle
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900 dark:text-slate-400"
                    >
                      {title || 'Confirm action'}
                    </DialogTitle>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500 dark:text-slate-400">
                        {children}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse sm:items-center">
                  <Button type="button" variant="error" onClick={onConfirm}>
                    {confirmLabel}
                  </Button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center px-3 py-2 text-sm font-medium text-gray-600 hover:text-gray-700 dark:text-slate-400 dark:hover:text-gray-300 sm:mt-0 sm:w-auto"
                    onClick={onCancel}
                  >
                    Cancel
                  </button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
