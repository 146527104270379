import React from 'react'
import clsx from 'clsx'

import { TableHeadProps } from './table-head'
export type { TableHeadProps }

export const TableHead: React.FC<TableHeadProps> = ({
  colSpan = 1,
  rowSpan = 1,
  className,
  children,
}) => {
  return (
    <th
      scope="col"
      colSpan={colSpan}
      rowSpan={rowSpan}
      className={clsx(
        'px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-slate-400',
        className,
      )}
    >
      {children}
    </th>
  )
}
