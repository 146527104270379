import React from 'react'
import { FileInputButtonProps } from './file-input-button'
import { Button } from '..'

export const FileInputButton: React.FC<
  React.PropsWithChildren<FileInputButtonProps>
> = ({ uploading, className, children, onChange, name = '' }) => {
  const fieldName = name.replace('Id', '')
  const inputRef = React.useRef<any>(null)

  const handleChange = React.useCallback(
    (e: any) => {
      e.preventDefault()
      console.log('File has been added')
      if (!uploading && e.target.files && e.target.files[0]) {
        onChange(e.target.files[0])
      }
    },
    [onChange, uploading],
  )

  const openFileExplorer = React.useCallback(
    (e: any) => {
      e.preventDefault()
      inputRef.current.value = ''
      inputRef.current.click()
    },
    [inputRef],
  )

  return (
    <>
      <input
        ref={inputRef}
        id={fieldName}
        name={fieldName}
        type="file"
        className="sr-only"
        multiple={false}
        onChange={handleChange}
        accept="image/*"
      />
      <Button
        type="button"
        className={className}
        isSubmitting={uploading}
        onClick={openFileExplorer}
      >
        {children}
      </Button>
    </>
  )
}
