import clsx from 'clsx'
import { HorizontalLineProps } from './horizontal-line'

export type { HorizontalLineProps }

export const HorizontalLine: React.FC<HorizontalLineProps> = ({
  verticalSpace = 'medium',
  className,
}) => {
  return (
    <hr
      className={clsx(
        'h-px my-8 bg-gray-200 border-0 dark:bg-gray-700',
        {
          'my-4': verticalSpace === 'small',
          'my-8': verticalSpace === 'medium',
          'my-12': verticalSpace === 'large',
        },
        className,
      )}
    ></hr>
  )
}
