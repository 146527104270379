import clsx from 'clsx'
import { CardProps } from './card'
export type { CardProps }

export const Card: React.FC<CardProps> = ({
  title,
  subTitle,
  title2,
  subTitle2,
  className,
}) => {
  return (
    <div className={clsx('rounded border border-gray-700 p-2 p-4', className)}>
      <div className="flex justify-between">
        <p className="text-gray-900 dark:text-slate-400 font-bold">{title}</p>
        <p className="text-gray-700">{subTitle}</p>
      </div>
      <div className="flex justify-between">
        <p className="text-gray-700">{title2}</p>
        <p className="text-gray-900 dark:text-slate-400 font-bold">{subTitle2}</p>
      </div>
    </div>
  )
}
