import { DisclosureButton } from '@headlessui/react'
import clsx from 'clsx'
import { ChevronRightIcon } from '@heroicons/react/24/solid'
import { AccordionButtonProps } from './accordion-button'

export type { AccordionButtonProps }

export const AccordionButton: React.FC<AccordionButtonProps> = ({
  open,
  children,
  className,
  onClick,
}) => {
  return (
    <DisclosureButton
      className={clsx(
        !className &&
          'flex w-full items-start justify-between text-left text-gray-900 dark:text-slate-400 bg-gray-100 px-2 py-3 dark:bg-slate-800 dark:text-white',
        className,
      )}
      onClick={onClick}
    >
      <span className="text-base font-medium leading-7">{children}</span>
      <span className="ml-6 flex h-7 items-center">
        <ChevronRightIcon
          className={clsx('h-6 w-6', {
            'rotate-0': !open,
            'rotate-90': open,
          })}
          aria-hidden="true"
        />
      </span>
    </DisclosureButton>
  )
}
