import React from 'react'
import clsx from 'clsx'
import { Checkbox } from '@headlessui/react'

import FormElement from '~ui/FormElement'

import type { DayOfMonthProps } from './day-of-month'

export type { DayOfMonthProps }

const domOptions: { label: string; value: string }[] = new Array(31)
  .fill('')
  .map((_, idx) => ({
    label: `${idx + 1}`,
    value: `${idx + 1}`,
  }))

export const DayOfMonthSelect = React.forwardRef<
  HTMLInputElement,
  DayOfMonthProps
>(
  (
    {
      name,
      disabled,
      multiple = true,
      selectMode,
      error,

      initialValue,
      selectedValue,
      onChange,
      ...props
    },
    ref,
  ) => {
    const [value, setValue] = React.useState<number[]>(initialValue ?? [])
    const [selected, setSelected] = React.useState<string | undefined>(
      selectedValue ? `${selectedValue}` : undefined,
    )
    const handleChange = React.useCallback(
      (val: string, checked: boolean) => {
        if (selectMode) {
          return
        }
        const currentArr: number[] = value
        const newVal = Array.from(
          new Set(
            checked
              ? [...currentArr, parseInt(val, 10)]
              : currentArr.filter((v) => v !== parseInt(val, 10)),
          ),
        )
        setValue(newVal)
        onChange(newVal)
      },
      [value, selectMode, onChange],
    )
    const handleClick = React.useCallback(
      (val: string) => {
        if (
          selectMode &&
          (value.includes(parseInt(val, 10)) || !value.length)
        ) {
          setSelected(val)
          onChange([parseInt(val, 10)])
        }
      },
      [value, selectMode],
    )

    return (
      <>
        <FormElement name={name} error={error} {...props}>
          <div className="w-80 mt-2 grid grid-cols-6 sm:grid-cols-7 md:grid-cols-7 gap-2">
            {domOptions.map((option) => (
              <Checkbox
                key={option.label}
                checked={
                  (selectMode && !value.length) ||
                  value?.includes(parseInt(option.value, 10))
                }
                onChange={(checked: boolean) => {
                  if (!disabled) {
                    handleChange(option.value, checked)
                  }
                }}
                onClick={() => handleClick(option.value)}
                className={({ focus, checked }) =>
                  clsx(
                    'flex items-center justify-center rounded-full w-10 h-10 text-sm font-semibold uppercase sm:flex-1',
                    {
                      'ring-2 ring-offset-2 ring-indigo-600': focus,
                      'text-white': checked,
                      'bg-highlight-600 text-white dark:bg-highlight-500 dark:text-slate-500':
                        selected === option.value,
                      'bg-primary-400':
                        selected !== option.value && checked && disabled,
                      'bg-primary-600 hover:bg-primary-500':
                        selected !== option.value && checked && !disabled,
                      'ring-1 ring-inset bg-white ring-gray-300 hover:bg-gray-50 dark:ring-slate-500':
                        selected !== option.value && !checked,
                      'text-gray-700 dark:bg-slate-800 dark:text-slate-600':
                        selected !== option.value && !checked && disabled,
                      'text-gray-900 dark:bg-slate-900 dark:text-slate-400 dark:hover:text-slate-800 dark:hover:bg-slate-400':
                        selected !== option.value && !checked && !disabled,
                      'cursor-pointer': !disabled,
                    },
                  )
                }
                // disabled={!option.inStock}
              >
                {option.label}
              </Checkbox>
            ))}
          </div>
        </FormElement>
      </>
    )
  },
)
