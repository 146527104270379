import React from 'react'
import clsx from 'clsx'
import { TextButtonProps } from './text-button'

export type { TextButtonProps }

export const TextButton: React.FC<TextButtonProps> = ({
  className,
  children,
  ...props
}) => (
  <button
    className={clsx(
      { 'text-primary-600 hover:text-primary-500 cursor-pointer': !className },
      className,
    )}
    role="link"
    {...props}
  >
    {children}
  </button>
)
