import { useCallback } from 'react'
import clsx from 'clsx'
import { XMarkIcon, CheckIcon } from '@heroicons/react/24/solid'
import { Variant } from '~ui/types'
import { PillProps } from './pill'

export type { PillProps }

export function Pill({
  icon,
  variant,
  title,
  checked,
  disabled,
  fixWidth,
  rounded,
  mode = 'checked',
  size = 'large',
  className,
  children,
  onClick,
}: PillProps) {
  const handleClick = useCallback(() => {
    if (onClick) {
      onClick(!!!checked)
    }
  }, [checked, onClick])
  let bg = 'bg-gray-300 dark:bg-slate-900'
  let text = 'text-gray-800 dark:text-slate-400'

  switch (variant) {
    case Variant.primary:
      bg =
        'bg-primary-100 hover:bg-primary-100 disabled:bg-primary-50 dark:bg-primary-700 dark:hover:bg-primary-800 disabled:dark:bg-primary-600'
      text =
        'text-primary-500 disabled:text-primary-400 dark:text-primary-300 disabled:dark:text-primary-500'
      if (checked) {
        bg = clsx('bg-primary-600 dark:bg-primary-900', {
          'hover:bg-primary-400 dark:hover:bg-primary-700': !!onClick,
        })
        text = 'text-primary-contrast'
      }
      break
    case Variant.secondary:
      bg =
        'bg-red-100 hover:bg-red-100 disabled:bg-red-50 dark:bg-red-700 dark:hover:bg-red-800 disabled:dark:bg-red-600'
      text =
        'text-red-500 disabled:text-red-400 dark:text-red-300 disabled:dark:text-red-500'
      if (checked) {
        bg = clsx('bg-red-400 dark:bg-red-900', {
          'hover:bg-red-200 dark:hover:bg-red-700': !!onClick,
        })
      }
      break
    case Variant.warn:
      bg =
        'bg-warn-700 hover:bg-warn-300 disabled:bg-warn-200 dark:bg-warn-700 dark:hover:bg-warn-800 disabled:dark:bg-warn-600'
      text =
        'text-warn-contrast disabled:text-warn-400 dark:text-warn-300 disabled:dark:text-warn-500'
      if (checked) {
        bg = clsx('bg-warn-400 dark:bg-warn-900', {
          'hover:bg-warn-200 dark:hover:bg-warn-700': !!onClick,
        })
      }
      break
    case Variant.highlight:
      bg =
        'bg-highlight-600 hover:bg-highlight-300 disabled:bg-highlight-200 dark:bg-highlight-700 dark:hover:bg-highlight-800 disabled:dark:bg-highlight-600'
      text =
        'text-highlight-contrast disabled:text-highlight-400 dark:text-highlight-300 disabled:dark:text-highlight-500'
      if (checked) {
        bg = clsx('bg-highlight-400 dark:bg-highlight-900', {
          'hover:bg-highlight-200 dark:hover:bg-highlight-700': !!onClick,
        })
      }
      break
      case Variant.light:
        bg = clsx(
          'bg-cyan-100 hover:bg-cyan-300 disabled:bg-cyan-50 dark:bg-cyan-600 dark:hover:bg-cyan-800 disabled:dark:bg-cyan-600',
          {
            'bg-cyan-400 dark:bg-cyan-900': checked,
            'hover:bg-cyan-200 dark:hover:bg-cyan-700': checked && !!onClick,
          }
        )
      
        text = clsx(
          'text-cyan-500 disabled:text-cyan-400 dark:text-cyan-300 disabled:dark:text-cyan-950'
        )
        break
    case Variant.error:
      bg =
        'bg-error-500 hover:bg-error-300 disabled:bg-error-200 dark:bg-error-700 dark:hover:bg-error-800 disabled:dark:bg-error-600'
      text =
        'text-secondary-contrast disabled:text-gray-400 dark:text-error-100 disabled:dark:text-error-500'
      if (checked) {
        bg = clsx('bg-error-500 dark:bg-error-900', {
          'hover:bg-error-300 dark:hover:bg-error-700': !!onClick,
        })
        text = 'text-white'
      }
      break
    case Variant.success:
      bg =
        'bg-green-400 hover:bg-green-300 disabled:bg-green-200 dark:bg-green-700 dark:hover:bg-green-800'
      text =
        'text-green-800 disabled:text-green-400 dark:text-green-100 disabled:dark:text-green-700'
      if (checked) {
        bg = clsx('bg-green-800 dark:bg-green-900', {
          'hover:bg-green-300 dark:hover:bg-green-700': !!onClick,
        })
        text = 'text-white'
      }
      break
  }
  let myIcon = checked && mode === 'checked' ? (
    <CheckIcon className="w-3.5 h-3.5" aria-hidden="true" />
  ) : (
    icon
  )

  let removeIcon = checked && mode === 'remove' && onClick ? (
    <XMarkIcon className="w-3.5 h-3.5" aria-hidden="true" />
  ) : null

  const classes = clsx(
    'min-h-5 text-center shadow-sm flex justify-center items-center flex-nowrap shrink-0 cursor-pointer disabled:cursor-default',
    bg,
    text,
    {
      'px-4 py-1 my-2 mx-2 font-semibold text-sm': size === 'large',
      'px-2.5 py-0.5 my-1 mx-1 font-medium text-xs': size === 'small',
      'px-1.5 py-0.5 grow-0 mx-1 font-medium text-xs': size === 'mini',
      'rounded-md': !rounded,
      'rounded-full': rounded,
      'w-32': fixWidth,
      'inline-block': !!onClick,
      relative: !className || !className.includes('absolute'),
    },
    className,
  )

  const iconClazz = clsx('absolute inset-y-0 left-1.5 flex items-center justify-center', text)
  const removeClazz = clsx(
    'absolute inset-y-0 right-2 flex items-center justify-center cursor-pointer',
    text,
  )

  return onClick ? (
    <button
      type="button"
      title={title}
      disabled={disabled}
      onClick={handleClick}
      className={classes}
    >
      {myIcon ? <span className={iconClazz}>{myIcon}</span> : null}
      <span
        className={clsx('whitespace-nowrap overflow-hidden text-ellipsis', {
          'pl-1.25': checked,
          'pl-4': !!myIcon && size !== 'small',
          'pl-2': !!myIcon && size === 'small',
          'pr-6': mode === 'remove' && checked && size !== 'small',
          'pr-4': mode === 'remove' && checked && size === 'small',
        })}
      >
        {children}
      </span>
      {mode === 'remove' && removeIcon ? (
        <span className={removeClazz}>{removeIcon}</span>
      ) : null}
    </button>
  ) : (
    <div className={classes} title={title}>
      {myIcon ? <span className={iconClazz}>{myIcon}</span> : null}
      <span
        className={clsx('whitespace-nowrap overflow-hidden text-ellipsis', {
          'pl-1.25': checked,
          'pl-4': !!myIcon && size !== 'small',
          'pl-2': !!myIcon && size === 'small',
        })}
      >
        {children}
      </span>
    </div>
  )
}
