import React from 'react'
import clsx from 'clsx'

import { Avatar } from '..'

import { UserCardType } from './user-card'

export const UserStackCard: React.FC<React.PropsWithChildren<UserCardType>> = ({
  id,
  firstName,
  lastName,
  title,
  avatar,
  className,
  onClick,
  children,
}) => {
  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.preventDefault()
      if (onClick && id) {
        onClick(id)
      }
    },
    [],
  )
  return (
    <div
      className={clsx(
        'flex items-center justify-between gap-x-6 p-3 py-5 border rounded-lg dark:border-slate-500',
        {
          'hover:bg-secondary-100 dark:bg-slate-800 dark:hover:bg-slate-600 cursor-pointer':
            onClick && id,
        },
        className,
      )}
      onClick={handleClick}
    >
      <div className="flex min-w-0 gap-x-4">
        <Avatar
          size="small"
          source={avatar?.source}
          alt={`${firstName} ${lastName}`}
          altContent={`${firstName.charAt(0)}${lastName.charAt(0)}`}
        />
        <div className="relative min-w-0 flex-1">
          <a href="#" className="focus:outline-none">
            <span className="absolute inset-0" aria-hidden="true" />
            <p className="text-sm font-bold text-gray-900 dark:text-slate-400">
              {firstName} {lastName}
            </p>
            <p className="truncate text-sm text-gray-500 dark:text-slate-300">
              {title}
            </p>
          </a>
        </div>
      </div>
      <div>{children}</div>
    </div>
  )
}
