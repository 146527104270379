import React from 'react'
import clsx from 'clsx'
import { Link as RemixLink, LinkProps } from '@remix-run/react'

export type { LinkProps }

export const Link: React.FC<LinkProps> = ({ className, children, ...props }) => (
  <RemixLink
    className={clsx('cursor-pointer',
      {
        'text-primary-600 hover:text-primary-500 dark:text-primary-400 hover:dark:text-primary-300':
          !className,
      },
      className,
    )}
    role="link"
    {...props}
  >
    {children}
  </RemixLink>
)
